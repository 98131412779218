import React, { useState } from "react";
import { useEffect } from "react";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import "./App.css";

function Dashboard() {
  // Get the URL search parameters
  const [zoomLevel, setZoomLevel] = useState(0.5);
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("dashboardId");
  const token = urlParams.get("token");
  useEffect(() => {
    // Get the value of the dashboardId parameter

    if (id && token) {
      async function fetchGuestToken() {
        const response = await fetch(`/api/guestToken?dashboardId=${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await response.json();
        return data.token;
      }
      const mountPoint = document.getElementById("superset-container");
      if (!mountPoint) return;
      embedDashboard({
        id,
        supersetDomain: "https://dashboard.prod.labs.lixil.com",
        mountPoint,
        fetchGuestToken: () => fetchGuestToken(),
        dashboardUiConfig: {
          hideTitle: true,
          hideTab: true,
          hideChartControls: true,
          filters: { visible: false, expanded: false },
        },
      });
    }
  }, [id, token]);

  if (!token)
    return (
      <div className="errorContainer">
        <h3>Please provide a token</h3>
      </div>
    );

  if (!id)
    return (
      <div className="errorContainer">
        <h3>Dashboard Id is required</h3>
        <h3>
          http://example.com/?dashboardId=74eb67a9-58b5-4c38-a131-6be41277ab47
        </h3>
      </div>
    );

  const handleRotate = () => {
    var supersetFrame = document.getElementById("superset-container");
    if (supersetFrame) supersetFrame.classList.toggle("landscape");
  };

  const handleOpen = () => {
    var options = document.getElementById("options");
    if (options) options.classList.toggle("open");
  };

  useEffect(() => {
    const viewport = document.querySelector("meta[name=viewport]");
    if (viewport)
      viewport.setAttribute(
        "content",
        `width=device-width, initial-scale=${zoomLevel}`
      );
  }, [zoomLevel]);
  const handleZoomIn = () => {
    if (zoomLevel + 0.1 < 1) setZoomLevel(zoomLevel + 0.1);
  };
  const handleZoomOut = () => {
    if (zoomLevel > 0.5) setZoomLevel(zoomLevel - 0.1);
  };

  return (
    <>
      <div className="utility">
        <div className="utility-container">
          <div id="options">
            <div className="option">
              <button className="btn" onClick={handleZoomOut}>
                -
              </button>
            </div>

            <div className="option">
              <button className="btn" onClick={handleZoomIn}>
                +
              </button>
            </div>

            <div className="option">
              <img
                className="icon"
                src={require("./rotate.png")}
                onClick={handleRotate}
              />
            </div>
          </div>

          <div className="menu">
            <img
              className="menu-icon"
              src={require("./close.png")}
              onClick={handleOpen}
            />

            <img
              className="menu-icon"
              src={require("./up-arrow.png")}
              onClick={handleOpen}
            />
          </div>
        </div>
      </div>
      <div id="superset-container"></div>
    </>
  );
}

export default Dashboard;
